<template>
  <div
    v-if="isReady"
    class="action-panel"
    :class="[hasShadow ? 'shadow' : null]"
  >
    <Container>
      <div class="wrapper-actions">
        <Back-button />

        <slot></slot>
      </div>
    </Container>
  </div>
  <div v-else>
    <Container>
      <div class="skeleton">
        <div class="wrapper-inner"></div>
      </div>
    </Container>
  </div>
</template>

<script>
import BackButton from "@/components/dom/BackButton";
import Container from "@/components/dom/Container";

export default {
  components: {
    BackButton,
    Container
  },
  props: {
    isReady: Boolean,
    hasShadow: Boolean
  }
};
</script>

<style lang="scss" scoped>
.action-panel {
  width: 100%;
  text-align: left;

  &.shadow {
    .wrapper-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 0px 10px 0px $grey-light;
      width: 100%;
      padding: 20px 30px;

      .back-button {
        color: #000000;
        font-size: 1.9rem;
        transition: 200ms;
        cursor: pointer;

        i {
          color: #000000;
          margin-right: 10px;
        }

        &:hover {
          i {
            transform: translateX(-5px);
          }
        }
      }
    }
  }

  .wrapper-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    min-height: 45px;
    margin: 0 0 15px 0;
    padding: 0;

    > * {
      margin: 0 30px 0 0;

      &:last-child {
        margin: 0;
      }
    }

    .back-button {
      color: #000000;
      font-size: 1.9rem;
      transition: 200ms;
      cursor: pointer;

      i {
        color: #000000;
        margin-right: 10px;
      }

      &:hover {
        i {
          transform: translateX(-5px);
        }
      }
    }
  }
}

.skeleton {
  text-align: left;

  .wrapper-inner {
    display: inline-flex;
    align-items: center;
    width: 90%;
    height: 80px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 0px $grey-light;
    margin: 0 30px 15px 30px;
    padding: 20px 30px;
  }
}
</style>
