<template>
  <div
    v-if="isReady"
    class="inner-panel"
    :class="[hasShadow ? 'shadow' : null]"
  >
    <div v-if="title != null || text != null" class="panel-head">
      <TitleBase v-if="title != null" :type="3">
        {{ title }}
      </TitleBase>
      <TextBase v-if="text != null" :type="3">
        {{ text }}
      </TextBase>
    </div>

    <div class="panel-body">
      <slot></slot>
    </div>

    <div v-if="$slots['footer']" class="panel-footer">
      <div class="wrapper-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="wrapper-loader">
      <Loader width="100" height="100" />
    </div>
  </div>
</template>

<script>
import Loader from "@/components/dom/Loader";
import TextBase from "@/components/dom/TextBase";
import TitleBase from "@/components/dom/TitleBase";

export default {
  components: {
    Loader,
    TextBase,
    TitleBase
  },
  props: {
    title: String,
    text: String,
    isReady: Boolean,
    hasShadow: Boolean
  }
};
</script>

<style lang="scss" scoped>
.inner-panel {
  margin: 15px 30px;
  border-radius: 8px;

  &.shadow {
    box-shadow: 0px 0px 10px 0px $grey-light;

    .panel-body {
      padding: 20px 30px;
    }
  }

  .panel-head {
    background-color: $main-color;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 15px;
  }

  .panel-body {
    min-height: 75%;
    overflow-y: auto;
    padding: 20px 5px;
    @include scrollbars(5px, $main-color-light, $grey-lighter);
  }

  .panel-footer {
    padding: 15px 0;

    .wrapper-footer {
      border-top: 1px solid $main-color-light;
    }
  }
}
</style>
