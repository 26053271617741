<template>
  <div v-if="isReady" class="table-panel">
    <div class="panel-body">
      <Container>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="internalSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup="updateSearch"
            ></v-text-field>
          </v-card-title>

          <slot></slot>
        </v-card>
      </Container>
    </div>
  </div>
  <div v-else>
    <Container>
      <div class="wrapper-loader">
        <Loader width="100" height="100" />
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/dom/Container";
import Loader from "@/components/dom/Loader";

export default {
  name: "Table-panel",
  components: {
    Container,
    Loader
  },
  props: {
    isReady: Boolean,
    search: String
  },
  data() {
    return {
      internalSearch: ""
    };
  },
  watch: {
    search(newValue) {
      this.internalSearch = newValue;
    }
  },
  methods: {
    updateSearch() {
      this.$emit("updateSearch", this.internalSearch);
    }
  }
};
</script>

<style lang="scss" scoped>
.table-panel {
  margin: 15px 0;

  .panel-body {
    padding: 10px 5px;

    .v-card {
      max-height: $main-panel-height;
      overflow: auto;
      @include scrollbars(5px, $main-color-light, $grey-lighter);
    }
  }
}
</style>
