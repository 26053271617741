var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-base",class:{
    default: _vm.weight === 1 || undefined,
    heavy: _vm.weight === 2,
    main: _vm.type === 1 || undefined,
    secondary: _vm.type === 2,
    thirdary: _vm.type === 3,
    center: _vm.center === 'center',
    left: _vm.center === 'left' || undefined,
    right: _vm.center === 'right'
  },style:(_vm.size ? ("font-size:" + _vm.size + "rem") : null)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }