<template>
  <p
    class="text-base"
    :class="{
      default: weight === 1 || undefined,
      heavy: weight === 2,
      main: type === 1 || undefined,
      secondary: type === 2,
      thirdary: type === 3,
      center: center === 'center',
      left: center === 'left' || undefined,
      right: center === 'right'
    }"
    :style="size ? `font-size:${size}rem` : null"
  >
    <slot></slot>
  </p>
</template>

<script>
/*
  type:
   1 => primary
   2 => secondary
   3 => thirdary

   center:
    center => center
   left => left
   right => right

   size:
   pass as parameter the number of rem u want to override
*/

export default {
  name: "Text-base",
  props: {
    type: Number,
    center: String,
    size: Number,
    weight: Number
  }
};
</script>

<style lang="scss" scoped>
.text-base {
  font-size: 1.6rem;
  background-color: $transparent !important;

  &.default {
    font-weight: 400;
  }

  &.heavy {
    font-weight: 700;
  }

  &.main {
    color: $main-color;
  }
  &.secondary {
    color: $second-color;
  }
  &.thirdary {
    color: $third-color;
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}
</style>
