<template>
  <span v-if="showbackButton" class="back-button" @click="goBack">
    <v-icon>mdi-arrow-left-circle</v-icon>
    <span>Back</span>
  </span>
</template>

<script>
export default {
  name: "Back-button",
  data() {
    return {
      showbackButton: window.history.length > 1
    };
  },
  methods: {
    goBack() {
      this.showbackButton ? this.$router.go(-1) : this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.back-button {
  color: #000000;
  font-size: 1.9rem;
  transition: 200ms;

  i {
    color: #000000;
    margin-right: 10px;
  }

  &:hover {
    i {
      transform: translateX(-5px);
    }
  }
}
</style>
